<template>
  <div class="h-screen flex w-full bg-img" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>
                <vs-input name="email" icon="icon icon-user" icon-pack="feather" label-placeholder="Email"
                  v-model="email" class="w-full no-icon-border" />

                <vs-input data-vv-validate-on="blur" type="password" name="password" icon="icon icon-lock"
                  icon-pack="feather" label-placeholder="Password" v-model="password"
                  class="w-full mt-6 no-icon-border" />

                <div style="justify-content: center; display: flex;">
                  <vs-button class="float-right" style="margin-top: 3%; margin-bottom: 3%" @click="loginauth">Login
                  </vs-button>
                </div>

                <vs-divider>OR</vs-divider>

                <div class="social-login flex flex-wrap justify-center">
                  <div class="social-login-buttons flex flex-wrap items-center mt-4">
                    <!-- GOOGLE -->
                    <googleSignIn style="margin-top: 3%" @log-in-user="logInUser"></googleSignIn>
                  </div>
                </div>

                <div id="mat"></div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
      <!-- <div class="flex flex-wrap justify-center">
        <b>{{ quote }}</b>
      </div> -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import router from "@/router";
import constants from "../../../constants.json";
import quoteGenerator from "random-quote-generator";
import googleSignIn from "../../components/pagesComponents/GoogleSignInComponent.vue";
// import EventBus from "../../components/eventbus.js";
// import acl from "../../store/access_control.js";
export default {
  data() {
    return {
      email: "",
      password: "",
      checkbox_remember_me: false,
      showerror: false,
      quote: "",
      team: '',
      sub_team: ''
    };
  },
  components: {
    googleSignIn,
  },
  mounted() {
    quoteGenerator.generateAQuote();
    // console.log(this.quote);
  },
  methods: {
    loginauth() {
      this.$vs.loading();
      axios
        .post(`${constants.SERVER_API}generateAccesstoken`, {
          username: this.email,
          password: this.password,
        })
        .then((response) => {
          console.log(response);
          if (
            response.data.status_code === 204 ||
            response.data.status_code === 200
          ) {
            this.SaveTeam(response.data.response.access_token);
            this.logInUser(response.data.response.access_token);
            // this.webSocketConnect(response.data.response.access_token);
            // this.checkAuthenticity(response.data.response.access_token);
            // localStorage.setItem(
            //   "userAccessToken",
            //   response.data.response.access_token
            // );
            // axios
            //   .get(`${constants.SERVER_API}getRole`, {
            //     headers: {
            //       Authorization: `Bearer ${localStorage.userAccessToken}`,
            //     },
            //   })
            //   .then((response) => {
            //     console.log(response);
            //     // console.log(`Response for GETROLE ${response.data}`);
            //     this.$vs.loading.close();
            //     localStorage.setItem("role", response.data);
            //     if (response.data === "cm1") {
            //       router.push("/");
            //     } else if (response.data === "cm4") {
            //       router.push("/adminDashboard");
            //     } else if (response.data === "cm2") {
            //       router.push("/cm2Form");
            //     } else if (response.data === "cm3") {
            //       router.push("/receipts");
            //     } else if (response.data === "wc") {
            //       router.push("/warehouse");
            //     } else if (response.data === "wa") {
            //       router.push("/Warehouseaccount");
            //     } else if (response.data === "ac1") {
            //       router.push("/acadsDashboard");
            //     } else if (response.data === "fi1") {
            //       router.push("/tallyReceipts");
            //     } else if (response.data === "ac2") {
            //       router.push("/acadsAdminDashboard");
            //     } else if (response.data === "fe") {
            //       router.push("/MrReco");
            //     } else if (response.data === "cmca") {
            //       router.push("/CallLogs");
            //     } else if (response.data === "baca") {
            //       router.push("/IIMLManagementcalllogs");
            //     } else if (response.data === "hr") {
            //       router.push("/attendanceEdit");
            //     } else if (
            //       response.data === "exed" ||
            //       response.data === "exed2"
            //     ) {
            //       router.push("/iimlDashboard");
            //     } else {
            //       this.$vs.notify({
            //         title: "You are Unauthorized",
            //         text: "Try logging in with different credentials",
            //         color: "danger",
            //         position: "top-center",
            //       });
            //       router.push("/pages/login");
            //     }
            //   });
          } else if (response.data.status_code === "401") {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Invalid Credentials",
              text: "Details provided by you are invalid",
              color: "danger",
              position: "top-center",
            });
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "You are Unauthorized",
              text: "Try logging in with different credentials",
              color: "danger",
              position: "top-center",
            });
          }
        });
    },
    logInUser(access_token) {
      this.SaveTeam(access_token);
      // this.saveWhatsappMessage(access_token);
      localStorage.setItem("userAccessToken", access_token);
      axios
        .get(`${constants.SERVER_API}getRole`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          console.log("role", response);
          // console.log(`Response for GETROLE ${response.data}`);
          this.$vs.loading.close();
          localStorage.setItem("role", response.data);
          let team = localStorage.getItem('team')
          // let sub_team = localStorage.getItem('sub_team')
          if (response.data === "cm1") {
            router.push("/");
          } else if (response.data === "cm4" && team === 'Admin' || team === 'ExEdGM' || team === 'Audit') {
            router.push("/scheduledVisits");
          } else if (response.data === "cm4" && team == 'GM') {
            router.push("/GMWorkDashboard");
          } else if (response.data === "cm4" && team == 'Accounts') {
            router.push("/enrollments");
          } else if (response.data === "cm4" && team == 'SR') {
            router.push("/workDashboard");
          } else if (response.data === "cm2") {
            router.push("/cm2Form");
          } else if (response.data === "cm3") {
            router.push("/receipts");
          } else if (response.data === "wc") {
            router.push("/warehouse");
          } else if (response.data === "wa") {
            router.push("/Warehouseaccount");
          } else if (response.data === "fi1") {
            router.push("/tallyReceipts");
          } else if (response.data === "ac2") {
            router.push("/acadsAdminDashboard");
          } else if (response.data === "ac1") {
            router.push("/acadsDashboard");
          } else if (response.data === "fe") {
            router.push("/MrReco");
          } else if (response.data === "cmca") {
            router.push("/CallLogs");
          } else if (response.data === "baca") {
            router.push("/IIMLManagementcalllogs");
          } else if (response.data === "hr") {
            router.push("/attendanceEdit");
          } else if (response.data === "exed" || response.data === "exed2") {
            router.push("/iimlDashboard");
          } else if (response.data === "wh1" || response.data === "wh2") {
            router.push("/DispatchDetails");
          } else {
            router.push("/entryRolePage");
            // this.$vs.notify({
            //   title: "You are Unauthorized",
            //   text: "Try logging in with different credentials",
            //   color: "danger",
            //   position: "top-center",
            // });
            // router.push("/pages/login");
          }
        });
    },
    SaveTeam(access_token) {
      axios
        .get(`${constants.SERVER_API}getTeam`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          console.log("team", response);
          // console.log(`Response for GETROLE ${response.data}`);
          this.$vs.loading.close();
          localStorage.setItem("team", response.data.team);
          localStorage.setItem("sub_team", response.data.sub_team);
        });
    },
    saveWhatsappMessage(access_token) {
      axios
        .get(`https://mfcomms.2x2.ninja/api/getSrWhatsappMessages`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          console.log("watsapp message",response.data);
          // let messages = [];
          // for (let i = 0; i < response.data.length; i++) {
          //   const element = response.data[i];
          //   const msg = element.whatsapp_message
          //   var message = {
          //     "id":element.id,
          //     "message":msg
          //   }
          //   messages.push(message)
          // }
          this.$vs.loading.close();
          localStorage.setItem("watsapp_message", JSON.stringify(response.data));
        });

    },
    // checkAuthenticity(access_token) {
    //   let url = "https://mfcomms.2x2.ninja/api/checkAuthenticity"
    //   axios
    //     .get(url, {
    //       headers: {
    //         Authorization: `Bearer ${access_token}`,
    //       }
    //     })
    //     .then((response) => {
    //       console.log("Authenticity check",response.data)
    //       if(response.data){
    //         let user_id = parseInt(response.data);
    //         // this.webSocketConnect(access_token,user_id);
    //       }
    //     }) .catch((error) => {
    //       this.$vs.notify({
    //           title: "Unable to Establish Connection",
    //           text: error,
    //           color: "danger"
    //         });
    //     });
    // },
    // webSocketConnect(access_token) {
    //   //try catch
    //   console.log("WEBSOCKET_PARAMS",access_token)
    //   let url = "wss://nt0crq7iw0.execute-api.ap-south-1.amazonaws.com/development/?auth_token=" + encodeURI(access_token);
    //   console.log("url",url)
    //   let socket = new WebSocket(url);

    //   socket.onopen = function (e) {
    //     console.log("API SOCKET CONNECTION",e)
    //     alert("[open] Connection established", e);
    //   };

    //   socket.onmessage = function (evt) {
    //     console.log("API SOCKET DATA",evt)
    //     console.log("API SOCKET MESSAGE",evt.data)

    //       alert("Message Received... : " + evt.data);
    //     };
    // }
    // webSocketConnect(access_token,user_id) {
    //   //try catch
    //   console.log("WEBSOCKET_PARAMS",access_token,user_id)
    //   let url = "wss://nt0crq7iw0.execute-api.ap-south-1.amazonaws.com/development/?auth_token=" +
    //     encodeURI(access_token) + "&user_id=" + encodeURI(parseInt(user_id));
    //     console.log("url",url)
    //   let socket = new WebSocket(url);

    //   // socket.on('open', () => alert("Connection established"));
    //   socket.onopen = function (e) {
    //     alert("[open] Connection established, send -> server", e);
    //   };
    // }
  }
};
</script>

<style lang="scss">
#page-login {
  .social-login {
    .bg-facebook {
      background-color: #1551b1;
    }

    .bg-twitter {
      background-color: #00aaff;
    }

    .bg-google {
      background-color: #1551b1;
    }

    .bg-github {
      background-color: #333;
    }
  }
}
</style>
