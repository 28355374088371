<template>
  <div>
    <GoogleLogin
      :params="params"
      :onSuccess="onSuccess"
      :onFailure="onFailure"
      class="vs-component vs-button float-right vs-button-primary vs-button-filled"
    >
      <!-- Login with Miles Education Email -->
      Login with Miles Education Email&nbsp;&nbsp;
      <svg
        aria-hidden="true"
        style="position: relative; top: 2px;"
        focusable="false"
        data-prefix="fab"
        data-icon="google"
        class="text-white h-4 w-4 svg-inline--fa fa-google fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 488 512"
      >
        <path
          fill="currentColor"
          d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
        ></path>
      </svg>
    </GoogleLogin>
  </div>
</template>

<script>
import GoogleLogin from "vue-google-login";
import constants from "../../../constants.json";
import axios from "axios";
export default {
  data() {
    return {
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      params: {
        client_id:
          "360383923261-ppk7gerj3afi09g091ks366cuhv82nsj.apps.googleusercontent.com",
      },
    };
  },
  components: {
    GoogleLogin,
  },
  methods: {
    onSuccess(googleUser) {
      console.log(googleUser);

      // This only gets the user information: id, name, imageUrl and email
      console.log(googleUser.getBasicProfile());
      // console.log("id_token",googleUser.getAuthResponse().id_token);
      this.getAccessToken(googleUser.getAuthResponse().id_token);
    },

    onFailure(googleError) {
      console.log(googleError);
    },

    getAccessToken(googleIdToken) {
      axios
        .get(
          `${
            constants.SERVER_API
          }getAccessTokenFromGoogleIdToken/${googleIdToken}`
        )
        .then((response) => {
          console.log("google token", response.data);
          this.$emit("log-in-user", response.data.access_token);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
